<template>
  <div class="system-container fixed_container">
    <a-menu v-model="current" mode="horizontal" class="menu_group fixed_menu" :defaultSelectedKeys="[$route.name]">
      <!-- <a-menu-item key="/financialManagementA/money" v-if="allRoutes.includes('money')">
        <router-link :to="{ name: 'money' }">资金管理</router-link>
      </a-menu-item>
      <a-menu-item key="/financialManagementA/income" v-if="allRoutes.includes('income')">
        <router-link :to="{ name: 'income' }">收入统计</router-link>
      </a-menu-item>
      <a-menu-item key="/financialManagementA/incomeAudit" v-if="allRoutes.includes('incomeAudit')">
        <router-link :to="{ name: 'incomeAudit' }">收入审核</router-link>
      </a-menu-item>
      <a-menu-item key="/financialManagementA/invoice" v-if="allRoutes.includes('invoice')">
        <router-link :to="{ name: 'invoice' }">发票管理</router-link>
      </a-menu-item>
      <a-menu-item key="/financialManagementA/contract" v-if="allRoutes.includes('contract')">
        <router-link :to="{ name: 'contract' }">创建合同</router-link>
      </a-menu-item>
      <a-menu-item key="/financialManagementA/settlementManager" v-if="allRoutes.includes('settlementManager')">
        <router-link :to="{ name: 'settlementManager' }">{{ +isAdmin === 2 ? '结算管理' : '支出管理' }}</router-link>
      </a-menu-item> -->
    </a-menu>
    <route-view></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
export default {
  name: 'FinancialManagementA',
  components: {
    RouteView
  },
  data () {
    return {
      current: ''
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes
    })
  },
  watch: {
    $route: {
      handler (val) {
        const routes = val.matched.concat()
        this.current = [routes.pop().path]
      },
      immediate: true
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
::v-deep.aggregate-container {
  background-color: #f7f7f7;
  .menu_group {
    margin-bottom: 10px;
  }
}
</style>
